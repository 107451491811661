<template>
  <v-main class="lighten-3">
    <v-container fluid>
      <v-row
          align="start"
          justify="space-around"
          style="padding: 5vh"
      >
        <h3>Объекты</h3>
        <v-btn
            tile
            color="success"
            @click="createProjectLink"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Создать объект
        </v-btn>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="projectsList"
          :items-per-page="25"
          class="elevation-1"
          dense
      >
        <template v-slot:item.id="{ item }">
          <a @click="editItem(item)">{{ item.id }}</a>
        </template>
<!--        <template v-slot:item.status="{ item }">-->
<!--          {{ buildTitle(item.status) }}-->
<!--        </template>-->
<!--        <template v-slot:item.deadline="{ item }">-->
<!--          {{ item.deadline | formatDate }}-->
<!--        </template>-->
<!--        <template v-slot:item.answers_deadline="{ item }">-->
<!--          {{ item.answers_deadline | formatDate }}-->
<!--        </template>-->
<!--        <template v-slot:item.title="{ item }">-->
<!--          <a @click="showItem(item)">{{ item.title }}</a>-->
<!--        </template>-->
<!--        <template v-slot:item.archived="{ item }">-->
<!--          <v-simple-checkbox-->
<!--              v-model="item.archived"-->
<!--              disabled-->
<!--          ></v-simple-checkbox>-->
<!--        </template>-->

<!--        <template v-slot:item.link="{ item }">-->
<!--          <v-icon small v-clipboard="getLink(item)">mdi-content-copy</v-icon>-->
<!--          <a :href="item.link">{{ item.link }}</a>-->
<!--        </template>-->

        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="workItem(item)"
          >
            mdi-eye
          </v-icon>
        </template>

      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
// import axios from '@/helpers/axiosConfig'

export default {
  name: 'IulProjects',
  data: () => ({
    headers: [
      {
        text: '#',
        value: 'id',
      },
      {
        text: 'Название проекта',
        value: 'title',
      },
      {
        text: 'Адрес объекта',
        value: 'address',
      },
      {
        text: 'Застройщик',
        value: 'name_developer',
      },
      {text: 'Действия', value: 'actions', sortable: false},
    ]
  }),
  computed: {
    ...mapGetters('iul_projects', {
      projectsList: 'list'
    }),
    ...mapState([
      'route', // vuex-router-sync
    ]),
  },

  methods: {
    ...mapActions('iul_projects', {
      fetchProjects: 'fetchList'
    }),
    fetchData() {
      return this.fetchProjects();
    },
    createProjectLink() {
      this.$router.push('/create_iul_project')
    },
    editItem(item) {
      this.$router.push('/edit_iul_project/' + item.id)
    },
    workItem(item) {
      this.$router.push('/iul_project/' + item.id)
    },
    getLink(item) {
      return document.location.origin + item.link;
    },

    buildTitle: function(status) {
      return this.status_items.find(x => x.id === status).title;
    },

  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData();
  }
}
</script>
